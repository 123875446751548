/**
 * an assorted collection of half-assed, half-baked solutions which are often
 * useful but not generally what you want to have
 * @packageDocumentation
 */
import {uuid} from 'uuidv4';
import _deepMerge from 'deepmerge';

/**
 * returns true if x is undefined, false otherwise
 * @category fns:predicates
 */
export const isUndefined = (x: any): x is undefined => x === undefined;

/**
 * returns true if x is null, false otherwise
 * @category fns:predicates
 */
export const isNull = (x: any): x is null => x === null;

/**
 * returns true if x is null or undefined, false otherwise
 * @category fns:predicates
 */
export const isNil = <T = any>(x: T): x is (null | undefined) => isUndefined(x) || isNull(x);

/**
 * returns true if x is not null or undefined, false otherwise
 * @category fns:predicates
 */
export const isNotNil = <T extends any | null | undefined = any>(x: T): T extends (null | undefined) ? false : true =>
  !isNil(x) as any;

/**
 * returns true if x is a string
 * @category fns:predicates
 */
export const isStr = (x: any): x is string => typeof x === 'string';

/**
 * returns true if x is a string and contains nothing (but optionally whitespace)
 * @category fns:predicates
 */
export const isEmptyStr = (x: any): x is string & boolean => isStr(x) && x.trim().length === 0;

/**
 * returns true if x is a string and contains characters other than whitespace
 * @category fns:predicates
 */
export const isNonEmptyStr = (x: any): x is string => isStr(x) && !isEmptyStr(x);

/**
 * returns true if x is a number (and is not NaN)
 * @category fns:predicates
 */
export const isNum = (x: any): x is number => typeof x === 'number' && !isNaN(x);

/**
 * returns true if x is a positive number (excluding 0)
 * @category fns:predicates
 */
export const isPos = (x: number): boolean => isNotNil(x) && isNum(x) && x > 0;

/**
 * returns true if x is a negative number (excluding 0)
 * @category fns:predicates
 */
export const isNeg = (x: number): boolean => isNotNil(x) && isNum(x) && x < 0;

/**
 * returns true if x is of type object
 * @category fns:predicates
 */
export const isObj = <T = any>(x: any): x is T => typeof x === 'object';

/**
 * returns true if x is of type boolean
 * @category fns:predicates
 */
export const isBoolean = (x: any): x is boolean => typeof x === 'boolean';

/**
 * returns true if x is exactly true
 * @category fns:predicates
 */
export const isTrue = (x: any): x is true => x === true;

/**
 * returns true if x is exactly false
 * @category fns:predicates
 */
export const isFalse = (x: any): x is false => x === false;

/**
 * returns true if x is anything but exactly true
 * @category fns:predicates
 */
export const isNotTrue = (x: any): boolean => !isTrue(x);

/**
 * returns true if x is anything but exactly false
 * @category fns:predicates
 */
export const isNotFalse = (x: any): boolean => !isFalse(x);

/**
 * returns true if x is an indexable structure (currently plain objects or arrays)
 * @category fns:predicates
 */
export const isIndexable = <T = any>(x: T): boolean => isObj(x) || isArray(x);

/**
 * returns true if k is a property of o, provided o is indexable
 * @param k
 * @category fns:objects
 */
export const hasProp = <T = any>(o: T, k: keyof T | string | number): boolean =>
  !isNil(o) && isIndexable(o) && o.hasOwnProperty(k);

/**
 * returns the value of property k in o, or d if not present (meaning it will return
 * falsy values if they are at the given key, and return the default value d
 * only if o has no property k)
 * @category fns:objects
 */
export const prop = <T = any, R = T[keyof T]>(o: T, k: keyof T | string | number, d?: R): R => (hasProp(o, k) ? (o[k as any] as R) : d);

/**
 * 'raw' version of {@link prop} to facilitate forced property access even when
 * the underlying object `o` inherits the requested prop `k` (which
 * causes hasOwnProperty checks to fail):
 * @category fns:objects
 */
export const prop__ = <T = any, R = any>(o: T, k: string | number, d?: R): R => (o[k] as R) || d;

/**
 * returns a selection ks of properties in o (reduction over ks with {@link prop})
 * @category fns:objects
 */
export const props = <T = any>(o: T, ks: Array<keyof T>): Record<keyof T, T[keyof T]> =>
  ks.reduce((_o, k) => ({..._o, [k]: prop(o, k)}), {} as T);

/**
 * returns the value at the given keypath kp in o
 * @category fns:objects
 */
export const propIn = <R = any>(o: object, kp: Array<string | number>, d?: R): R => {
  if (isEmpty(kp)) {
    // if we have no more keys, return current object or default value
    return isNil(o) ? d : o as any;
  } else if(isIndexable(o)) {
    // otherwise, perform recursive lookup with the current path head
    const k = prop(kp, 0) as keyof typeof o;
    const v = prop(o, k);

    return propIn(v, kp.slice(1), d);
  } else {
    // we know here that either we either encountered an empty keypath (meaning there was nothing
    // found), or a non-indexable value (meaning we can't look up values even if we have keys),
    // so we can just bail out and return the default value
    return d;
  }
};

/**
 * returns a new object with all the properties from o as well as a new key k
 * with the value v added
 * @category fns:objects
 */
export const assoc = <T = any>(o: T, k: string | number | symbol, v: any): T & Record<typeof k, typeof v> =>
  Object.assign({}, o, {[k]: v});

/**
 * returns a new object with all the properties from o as well as a new keypath kp
 * with the value v added – this was basically copied from ramda so it has
 * that variants' mechanics
 * @category fns:objects
 */
export const assocIn = <T = any>(o: T, kp: Array<string | number>, v: any): T => {
  if (kp.length === 0) {
    return v;
  }
  let idx = kp[0];
  if (kp.length > 1) {
    let nextObj = (!isNil(o) && hasProp(o, idx)) ? o[idx] : isNum(kp[1]) ? [] : {};
    v = assocIn(nextObj, Array.prototype.slice.call(kp, 1), v);
  }
  if (isNum(idx) && isArray(o)) {
    let arr = [].concat(o);
    arr[idx] = v;
    return arr as any;
  } else {
    return assoc(o, idx, v);
  }
};

/**
 * returns a new object with all the properties from o, minus the keys in ks
 * @category fns:objects
 */
export const dissoc = <T = any>(o: T, ...ks: Array<keyof T>): Record<keyof T, T[keyof T]> => {
  const strKs = ks.map((k): string => `${k}`);
  const _ks = keys(o).filter(k => strKs.indexOf(k as string) < 0);

  return props(o, _ks);
};

/**
 * updates the value of k, if any, in o by applying f and using its return value as the new one
 * @category fns:objects
 */
export const update = <T = any>(o: T, k: string, f: Function, ...args: any[]): T =>
  assoc(o, k, f.apply(undefined, [prop(o, k, null), ...args]));

/**
 * same as {@link update} just for typescript Maps
 * @category fns:util
 */
export const updateMap = <K = any, V = any>(m: Map<K, V>, k: K, f: (v: V) => V, ...args): Map<K, V> => {
  return m.set(k, f.apply(f, [m.get(k), ...args]));
};

/**
 * returns an array of enumerable properties in o
 * @category fns:objects
 */
export const keys = <T = any>(o: T): Array<keyof T> => (isNotNil(o) ? Object.keys(o) as Array<keyof T> : []);

/**
 * returns true if x is of type function
 * @category fns:predicates
 */
export const isFn = (x: any): x is Function => typeof x === 'function';

/**
 * returns true if x is of type array
 * @category fns:predicates
 */
export const isArray = <T = any>(x: any): x is Array<T> => Array.isArray(x);

/**
 * returns true if x is an array or object without enumerable properties
 * @category fns:predicates
 */
export const isEmpty = (coll: any[] | object): boolean =>
  isNil(coll) || (isArray(coll) ? (<any[]>coll).length === 0 : isObj(coll) ? keys(coll).length === 0 : true);

/**
 * complement of isEmpty
 * @category fns:predicates
 */
export const isNotEmpty = (coll: any[] | object): boolean => !isEmpty(coll);

export type Scalar = string | number | boolean;

/**
 * returns true if x is a primitive value (string, number, boolean)
 * @see Scalar
 * @category fns:predicates
 */
export const isScalar = (x: any): x is Scalar => isNotNil(x) && (isStr(x) || isNum(x) || isBoolean(x));

/**
 * performs a reduction using f over the keys of o
 * @category fns:colls
 */
export const reduceKeys = <T = any, R = any>(o: T, f: (_: R, __: keyof T) => R, i: R = null): R =>
  keys(o).reduce(f, i);

/**
 * performs a reduction using f over the keys and values of o; calls f with the accumulator,
 * the key and its respective value as arguments
 * @category fns:colls
 */
export const reduceKeyvals = <T = any, R = any>(
  o: T,
  f: (_: R, __: keyof T, ___: T[keyof T]) => any,
  i: R = null
): object | any => reduceKeys(o, (_o, k) => f(_o, k, o[k]), i);

/**
 * returns a (pseudo-)random integer between start and end, defaulting to 0 and 1 respectively
 * @category fns:rand
 */
export const randInt = (start?: number, end?: number): number =>
  isNotNil(start) && start === end ? start : Math.floor((start || 0) + Math.random() * ((end || 1) + (start || 0)));

/**
 * returns a (pseudo-)random value in collection coll
 * @category fns:rand
 */
export const randNth = <T = any>(coll: T[]): T => coll[randInt(0, coll.length)] as T;

/**
 * returns a random UUIDv4
 * @category fns:rand
 */
export const randomUUID = (): string => uuid();

// export const hashMap = <K, V>(o?: object): Map<K, V> => isEmpty(o)
//   ? new Map<K, V>()
//   : new Map<K, V>(reduceKeyvals(o, (es, k, v) => ([...es, [k, v]]), []));

/**
 * returns true if el is an item of coll
 * @category fns:predicates
 */
export const isIn = <T = any>(coll: Array<T>, el: T): boolean => isNotNil(coll) && coll.indexOf(el) >= 0;

/**
 * creates a new Set from a given array
 * @category fns:util
 */
export const arrToSet = <T = any>(arr: T[]): Set<T> => new Set<T>(arr);

/**
 * creates a new array of the values in set
 * @category fns:util
 */
export const setToArr = <T = any>(set: Set<T>): T[] => Array.from(set.values());

// ----------------------------------------------------------------------
// here be dragons: all sorts of random utility functions which are
// neither particularly well implemented nor generally useful

export const propComp = (a: object, b: object, p: string, pred: (_: any, __: any) => boolean) =>
  pred(prop(a, p), prop(b, p));

export const propEq = (a: object, b: object, p: string) => propComp(a, b, p, (x, y) => x === y);

export const propNeq = (a: object, b: object, p: string) => propComp(a, b, p, (x, y) => x !== y);

export const constrain = (n: number, lo: number, hi: number) => Math.max(Math.min(n, hi), lo);

/**
 * constrains the given number n from one domain [start1, stop1] to another [start2, stop2]
 * @category fns:util
 */
export const mapRange = (
  n: number,
  start1: number,
  stop1: number,
  start2: number,
  stop2: number,
  withinBounds: boolean = false
) => {
  const newVal = ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;

  return !withinBounds ? newVal : start2 < stop2 ? constrain(newVal, start2, stop2) : constrain(newVal, stop2, start2);
};

export const norm = (n: number, start: number, stop: number) => mapRange(n, start, stop, 0, 1);

export const normPerc = (n: number, start: number, stop: number) => mapRange(n, start, stop, 0, 100, true);

export const range0 = (n: number): number[] => [...Array(n).keys()];

/**
 * creates a new array containing numbers from min to max, offset by an optional step
 * @category fns:util
 */
export const range = (min: number, max: number, step: number = 1): number[] => {
  const arr = [];
  const totalSteps = Math.floor((max - min - 1) / step);
  for (let ii = 0; ii <= totalSteps; ii++) {
    arr.push(ii * step + min);
  }
  return arr;
};

/**
 * returns a new (variadic) function that always returns the original x
 * @category fns:util
 */
export function constantly<T = any>(x: T): (..._: any[]) => T {
  return function (..._: any[]) {
    return x;
  };
}

/**
 * variadic function merging all given objects left-to-right
 * @category fns:objects
 */
export const shallowMerge = <T>(...os: object[]): T => <any>os.reduce((_os, o) => <T>Object.assign({}, _os, o), {});

// an array merger is a function (target, source, opts) => Array, where target is the new and source the old array
// - @see https://www.npmjs.com/package/deepmerge#arraymerge
export const ARRAY_MERGERS = {
  // merges new old array with the new array
  MERGE: <TGT = any, SRC = any, OPT = any>(target: TGT[], source: SRC[], _: OPT): Array<TGT | SRC> =>
    ([...target, ...source]),

  // uses Set to create an array with the distinct items in old & new array
  DISTINCT: <TGT = any, SRC = any, OPT = any>(target: TGT[], source: SRC[], _: OPT): Array<TGT | SRC> =>
    Array.from(new Set([...target, ...source])),

  // always just use the new array (overwrite)
  REPLACE: <TGT = any, SRC = any, OPT = any>(_: TGT[], source: SRC[], __: OPT): SRC[] =>
    source,

  // never uses any value of the new array, keeping the old one as-is
  KEEP: <TGT = any, SRC = any, OPT = any>(target: TGT[], _: SRC[], __: OPT): TGT[] =>
    target
};

export const ARRAY_MERGE_MERGE = ARRAY_MERGERS.MERGE;
export const ARRAY_MERGE_DISTINCT = ARRAY_MERGERS.DISTINCT;
export const ARRAY_MERGE_REPLACE = ARRAY_MERGERS.REPLACE;
export const ARRAY_MERGE_KEEP = ARRAY_MERGERS.KEEP;

const _deepMergeDefaultOpts = {
  arrayMerge: ARRAY_MERGERS.DISTINCT
};

export interface IDeepMergeOptions {
  arrayMerge?: (a: any, b: any, c: any) => any;
  isMergeableObject?: (x: any) => boolean;
  customMerge?: (a: any, b: any) => any;
}

/**
 * returns a new object which is the result of recursively merging the objects a and b
 * using the given options:
 *   - arrayMerge: function with wich to merge arrays; see e.g. ARRAY_MERGERS
 *   - isMergeableObject: predicate determining if a value is mergeable
 *   - customMerge: function which can be used to override the default merge
 *                  behavior for a property, based on the property name
 * @see https://www.npmjs.com/package/deepmerge#options
 * @category fns:objects
 */
export const deepMerge = (a: object, b: object, opts?: IDeepMergeOptions): object =>
  // @ts-ignore
  _deepMerge(a, b, {..._deepMergeDefaultOpts, ...opts});

/**
 * given a collection of values coll, returns whether all values
 * satisfy the predicate pred
 * @category fns:predicates
 */
export const isEvery = (pred: (x: any) => boolean, coll: any[]): boolean => {
  return coll.map(pred).reduce((r, _r) => _r && r, true);
};

/**
 * given a collection of values coll, returns whether at least one value
 * satisfied the predicate pred. will break from iteration when a truthy
 * value is returned from pred
 * @category fns:predicates
 */
export const isAny = (pred: (x: any) => boolean, coll: any[]): boolean => {
  for (let x of coll) {
    if (pred(x)) {
      return true;
    }
  }
  return false;
};

/**
 * given a function f, will invoke f and return a tuple of [execution-time, results]
 * @category fns:util
 */
export const timed = <R = {}>(f: () => R): [number, R] => {
  const _t0 = performance.now();
  const r = f();
  return [performance.now() - _t0, r];
};

/**
 * given a string s and a map rs of replacements, will perform a reduction over the keys
 * of rs, treating them as regular expressions, and replacing occurances of them in s.
 * replacement values can be a string or a function, the latter will be called for
 * each matcher with the current version of the output string, the matches and
 * the original string. returns the resulting string (see fns.test.ts).
 * @category fns:str
 */
export const replaceInStr = (
  s: string,
  rs: { [match: string]: string | Function } | Array<[RegExp, string | Function]>
): string => {
  const replacements: Array<[RegExp, string | Function]> = (isArray(rs)
    ? rs
    : keys(rs).map(k => ([new RegExp(`${k}`, 'g'), rs[k]]))) as any;

  return replacements.reduce((_s: string, _r: [RegExp, string | Function]): string => {
    const [m, r] = _r;
    return _s.replace(m, isStr(r) ? r : r(_s, _s.match(m), s));
  }, s);
};

export const conformBool = (x: any): boolean => {
  if (isBoolean(x)) return x;
  if (isNonEmptyStr(x)) return x.toLowerCase().trim() === 'true';
  return false;
};

/**
 * returns a new object from o containing all nested objects flattened with
 * their keys joined by sep (defaults to '.')
 * @category fns:objects
 */
export const flattenKeys = <T = any>(o: T, sep: string = '.'): T => {
  let toReturn = {} as T;

  for (let i in o) {
    if (!o.hasOwnProperty(i)) continue;
    if (isObj(o[i]) && o[i] !== null) {
      let flatObject = flattenKeys(o[i], sep);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x))
          continue;
        // @ts-ignore
        toReturn[i + sep + x] = flatObject[x];
      }
    } else {
      // @ts-ignore
      toReturn[i] = o[i];
    }
  }
  return toReturn;
};


export const error = (message?: string): Error => new Error(message || 'Unknown error');

export const throwError = <R = void>(message?: string): R => {
  throw error(message);
};

export type FunctionReturning<R = any> = (..._: any[]) => R;

export const sum = (...args: number[]): number => args.reduce((t, c) => t + c, 0);

export const product = (...args: number[]): number => args.reduce((t, c) => t * c, 1);

const str_ = (args: any[]): string => str.apply(str, args);

/**
 * concatenates non-null (or undefined) arguments after calling toString on them
 */
export const str = (...args: any[]): string => args
  .filter(isNotNil)
  .map(x => x.toString())
  .join('');

export type StringEncoding = string | number | Date | boolean | undefined | null | { toString(): string; };

export const coerceStr = (x: StringEncoding): string | null => {
  if (isNil(x)) {
    return null;
  }

  if (isStr(x)) {
    return x;
  }

  if (hasProp(x, 'toString')) {
    return x.toString();
  }

  return null;
};

export interface LimitStringOptions {
  elisionAnchor?: 'start' | 'center' | 'end';
  elision?: string;
}

const defaultLimitStrOpts: LimitStringOptions = {
  elisionAnchor: 'end',
  elision: '…',
};

export const limitStr = (x: StringEncoding, limit: number, opts?: LimitStringOptions): string | null => {
  const {elisionAnchor, elision} = {...defaultLimitStrOpts, ...opts};
  const s = coerceStr(x);

  if (isNil(s) || isEmptyStr(s)) {
    return null;
  } else if (s.length <= limit) {
    return s;
  } else {
    switch (elisionAnchor) {
      case 'start': {
        const offset = s.length - limit;
        return `${elision}${s.substr(offset)}`;
      }
      case 'end': {
        return `${s.substr(0, limit)}${elision}`;
      }
      case 'center': {
        const l1 = Math.floor(limit / 2);
        const l2 = s.length - Math.ceil(limit / 2);
        return `${s.substr(0, l1)}${elision}${s.substr(l2)}`;
      }
      default:
        throw new Error('invalid elision anchor for string limit');
    }
  }
};

/**
 * function version of Array.push fixed to 1 argument
 * @category fns:colls
 */
export const push = <T = any>(coll: T[], x: T): T[] => {
  coll.push(x);
  return coll;
};

/**
 * like {@link push} but variadic
 * @category fns:colls
 */
export const conj = <T = any>(coll: T[], ...xs: T[]): T[] => {
  coll.push.apply(coll, xs);
  return coll;
};

/**
 * like {@link conj} but taking an array of arguments
 * @category fns:colls
 */
export const concat = <T = any>(coll: T[], xs: T[]): T[] => {
  coll.push.apply(coll, xs);
  return coll;
};

/**
 * a function returning its arguments
 * @category fns:util
 */
export const identity = <T = any>(x: T): T => x;

/**
 * returns a partially applied version of f
 * @category fns:util
 */
export const partial = <R = any>(f: FunctionReturning<R>, ...args: any[]): FunctionReturning<R> =>
  (...rest: any[]): R => f.apply(f, [...args, ...rest]);

/**
 * given a function f and a number of miliseconds wait, returns a new function which
 * only calls f after an amount of wait miliseconds has passed without calling f
 * again
 * @category fns:util
 */
export const debounce = <T = Function | ((...args: any[]) => any)>(f: T, wait: number, immediate: boolean = false): T => {
  let timeout: number | null;

  return function () {
    // @ts-ignore
    let context = this;
    let args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) {
        (f as unknown as Function).apply(context, args);
      }
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout as number);
    timeout = (setTimeout(later, wait) as unknown) as number;
    if (callNow) {
      (f as unknown as Function).apply(context, args);
    }
  } as unknown as T;
};
