import {Observable, Subject} from 'rxjs';
import {isAny, partial, randomUUID} from './fns';

export interface FileEntity {
}

export interface FileInputAttributes {
  multiple?: boolean,
  accept?: string
}

/**
 * returns a new HTMLInputElement of type file equipped with some defaults.
 * takes an optional map of FileInputAttributes to control the inputs
 * behavior
 */
export const createFileInputElement = (attributes?: FileInputAttributes): HTMLInputElement => {
  let input = document.createElement('input');

  input.id = `file-upload-${randomUUID()}`;
  input.type = 'file';
  input.style.position = 'absolute';
  input.style.visibility = 'hidden';
  input.style.top = '0px';
  input.style.left = '0px';
  input.style.zIndex = '-100';

  input.multiple = !!attributes.multiple;

  if (attributes.accept) {
    input.accept = attributes.accept;
  }

  return input;
};

const listenFileInputEvents = ($: Subject<File | FileList>, input: HTMLInputElement, attrs: FileInputAttributes) => {
  input.addEventListener('change', (ev) => {
    const el = <HTMLInputElement>ev.target;

    $.next(attrs.multiple
      ? el.files
      : (el.files.length > 0 ? el.files[0] : null)
    );

    $.complete();
  });

  input.addEventListener('error', (err) => $.error(err));

  return $;
};

type FileDialogContext = { files: Observable<File | FileList>, subject: Subject<File | FileList>, input: HTMLInputElement };

const createFileInput = (options: FileInputAttributes): FileDialogContext => {
  const attrs = {multiple: false, ...options};
  const input = createFileInputElement(attrs);
  const subject = listenFileInputEvents(new Subject<File | FileList>(), input, attrs);

  return {
    input,
    subject,
    files: subject.asObservable()
  };
};

const actuateFileDialog = (context: FileDialogContext): Observable<File | FileList> => {
  const {files, input, subject} = context;

  let blurListener;

  const focusListener = () => {
    window.setTimeout(() => {
      if (document.getElementById(input.id)) {
        window.removeEventListener('blur', blurListener);
        window.removeEventListener('focus', focusListener);

        if (!subject.closed) {
          if (!input.files || !input.files.length) {
            subject.next(null);
          }
          subject.complete();
        }

        input.remove();
      }
    }, 64);
  };

  blurListener = () => window.addEventListener('focus', focusListener);

  window.addEventListener('blur', blurListener);
  document.body.appendChild(input);

  input.focus();
  input.click();

  return files;
};

/**
 * returns an observable which, when subscribed, will spawn a new input element in the DOM,
 * associate some listeners, and stream File|FileList results (depending on the 'multiple'
 * option) to subscribers
 * the fact that this returns null when you cancel the dialog seems to be a minor revolution:
 * https://stackoverflow.com/questions/4628544/how-to-detect-when-cancel-is-clicked-on-file-input/49897073
 * @category platform
 */
export const openFileDialog = (options?: FileInputAttributes): Observable<File | FileList> =>
  actuateFileDialog(createFileInput(options));

export const previewFile = (url: string | URL, width?: number, height?: number): Observable<Window> => {
  return new Observable<any>($ => {
    const w = width || 720;
    const h = height || 720;

    $.next(window.open(url.toString(), '_blank', `width=${w},height=${h}`));
  });
};


/**
 * some frequently used keyboard key names
 * @category platform
 */
export enum NamedKey {
  Enter = 'Enter',
  Escape = 'Escape',
  Tab = 'Tab',
  Backspace = 'Backspace',
  Delete = 'Delete',
  ArrowUp = 'ArrowUp',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
}

/**
 * enum holding keyboard modifier key names
 * @category platform
 */
export enum ModifierValue {
  alt = 'alt',
  shift = 'shift',
  ctrl = 'ctrl',
}

/**
 * type corresponding to ModifierValue
 */
export type Modifier = 'alt' | 'ctrl' | 'shift';

export type Key = NamedKey | string;

/**
 * given a KeyboardEvent e and a Modifier m, returns true if the respective
 * modifier is active on the event (useful for checking this programatically)
 */
export const isModifier = (e: KeyboardEvent, m: Modifier): boolean => {
  switch (m) {
    case 'alt':
      return e.altKey;
    case 'shift':
      return e.shiftKey;
    case 'ctrl':
      return e.ctrlKey;
    default:
      return false;
  }
};

/**
 * given a KeyboardEvent e and a Key k, returns true if the respective key matches
 * the key of the event. an optional Modifier m can be passed to also check
 * against that
 */
export const isKey = (e: KeyboardEvent, k: Key, m?: Modifier): boolean => e.key === k && (m ? isModifier(e, m) : true);

/**
 * like isKey but accepts multiple keys and does no checks for modifiers
 */
export const isAnyKey = (e: KeyboardEvent, ks: Array<Key>): boolean => isAny(partial(isKey, e), ks);
